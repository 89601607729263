<template>
  <div class="layout">
    <h1 class="h1">{{detail.title}}</h1>
    <div class="author-info">
      <avatar class="cover" :url="philosopher.photoUrl"/>
      <span class="name">{{philosopher.penName}}</span>
      <span class="name time">{{detail.addDate|formatDate('yyyy-MM-dd hh:mm')}}</span>
    </div>
    <div class="summary" v-if="detail.introduction">
      <div class="hd">{{$t('ArticleIntroduction')}}</div>
      <div class="bd">{{detail.introduction}}</div>
    </div>
    <div class="travels-cover" v-if="detail.coverUrl">
      <img class="img" :src="detail.coverUrl"/>
    </div>
    <div class="rich-text" v-html="detail.content"></div>
    <div class="keywords" v-if="detail.keywords">
      <div class="label">{{$t('Keyword')}}：</div>
      <div class="cont">
        <router-link class="item"
                     target="_blank"
                     v-for="(item,index) of detail.keywords"
                     :key="index"
                     :to="{name:'TravelsKeyword',params:{id:travelsId},query:{keyword:item.keyword}}">
          {{item.keyword}}
        </router-link>
      </div>
    </div>
    <div class="author-brief cl" v-if="philosopher.philosopherId">
      <avatar class="cover" :url="philosopher.photoUrl"/>
      <div class="txts">
        <p class="name">{{philosopher.penName}}</p>
        <p>{{philosopher.introduction}}</p>
      </div>
      <router-link class="btn-more" :to="{name:'TravelerDetail',params:{id:philosopher.philosopherId}}">
        {{$t('MoreArticles')}} &gt;
      </router-link>
    </div>
    <div class="share">
      <div class="hd">{{$t('ShareToSocialPlatform')}}</div>
      <div class="bd">
        <a @click="shareToFacebook">
          <img class="img" src="../../assets/images/icon/facebook-big.png"/>
        </a>
        <a :href="twitterUrl" data-size="large" v-if="twitterUrl">
          <img class="img" src="../../assets/images/icon/twitter.png"/>
        </a>
      </div>
    </div>
    <div class="list-group" v-if="moreTravels.length">
      <div class="hd">{{$t('MoreArticles')}}</div>
      <div class="bd cl">
        <travels-item v-for="item of moreTravels" :datas="item" :key="item.travelsId"/>
      </div>
    </div>
    <div class="list-group" v-if="featuredTravels.length">
      <div class="hd">{{$t('FeaturedArticle')}}</div>
      <div class="bd cl">
        <travels-item v-for="item of featuredTravels" :datas="item" :key="item.travelsId"/>
      </div>
    </div>
  </div>
</template>
<script>
  import Avatar from '../../components/Avatar';
  import TravelsItem from './components/TravelsItem';
  import {getTravelsHomeDetail} from '../../api/travels';
  import {loadTwitter, initFacebook} from '../../utils/thirdParty';

  export default {
    name: 'TravelsDetail',
    data() {
      return {
        travelsId: '',
        detail: {},
        philosopher: {},
        moreTravels: [],
        featuredTravels: []
      };
    },
    computed: {
      shareUrl() {
        return window.location.origin + this.$route.fullPath;
      },
      twitterUrl() {
        const title = this.detail.title;
        if (!title) {
          return '';
        }
        return `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(this.shareUrl)}`;
      }
    },
    components: {Avatar, TravelsItem},
    mounted() {
      initFacebook();
      loadTwitter();
    },
    created() {
      this.travelsId = this.$route.params.id;
      this.getDetail();
    },
    methods: {
      getDetail() {
        getTravelsHomeDetail(this.travelsId).then(res => {
          const {value} = res;
          this.detail = value.travels;
          this.philosopher = value.philosopher || {};
          this.moreTravels = value.moreTravels;
          this.featuredTravels = value.featuredTravels;
        });
      },
      // 分享到facebook
      shareToFacebook() {
        const type = 'website';
        const title = this.detail.title;
        const description = this.detail.introduction;
        const image = this.detail.coverUrl;
        FB && FB.ui({method: 'share', href: this.shareUrl, type, title, description, image}, (response) => {
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .h1{margin:30px 0;text-align:center;font-size:48px;}
  .author-info{
    text-align:center;
    .cover{display:inline-block;width:40px;height:40px;vertical-align:middle;}
    .name{display:inline-block;margin-left:10px;vertical-align:middle;font-size:24px;}
    .time{margin-left:60px;}
  }
  .summary{
    margin-top:56px;padding:30px 60px;background-color:#f4f4f4;
    .hd{font-size:24px;margin-bottom:8px;}
  }
  .rich-text{margin-top:30px;}
  .author-brief{
    position:relative;margin-top:30px;padding:30px 180px 30px 60px;border:1px solid #d9d9d9;
    .cover{float:left;width:120px;height:120px;border:4px solid rgba(254, 112, 97, 0.4);}
    .txts{margin-left:140px;}
    .name{margin-bottom:8px;font-size:24px;}
    .btn-more{position:absolute;bottom:30px;right:30px;color:#ff6f61;}
  }
  .share{
    margin:60px 0;padding:20px 0 30px;text-align:center;background-color:#f4f4f4;
    .hd{color:#817268;}
    .bd{
      margin-top:20px;font-size:0;
      a{display:inline-block;width:24px;height:24px;margin:0 10px;cursor:pointer;}
    }
  }
  .list-group{
    .hd{
      position:relative;margin:10px 0 30px;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
  }
  .keywords{
    display:table;margin:30px 0 -10px;line-height:32px;
    .label{display:table-cell;white-space:nowrap;font-size:16px;font-weight:bold;}
    .cont{display:table-cell;}
    .item{display:inline-block;margin-right:10px;margin-bottom:10px;padding:0 12px;vertical-align:top;white-space:nowrap;background-color:#f4f4f4;border-radius:4px;}
  }
  .travels-cover{height:540px;}
</style>
